<template>
    <div>
        <component :is="signObj" :id="route.params.signatureId" />
    </div>
</template>

<script>
import { ref, inject, computed } from 'vue'
import { useRoute } from 'vue-router'
import ImgSign from '@/components/ImgSign.vue'

export default {
    name: 'Signature',
    setup () {
        const axios = inject('axios')
        const route = useRoute()

        const signObj = computed(() => {
            return ImgSign
        })

        return { 
            route, ImgSign, signObj
        }
    }
}
</script>

<style>

</style>